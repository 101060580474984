/*
 * @Description: 填写描述
 * @Author: 填写作者
 * @Date: 2022-01-10 23:36:18
 */
import Mock from 'mockjs';
import { isSSR } from '@/utils/is';

import './user';
import './message-box';

if (!isSSR) {
  Mock.setup({
    timeout: '500-1500',
  });
}
