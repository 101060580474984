/*
 * @Description: 填写描述
 * @Author: 填写作者
 * @Date: 2022-01-10 23:36:19
 */
export const defaultRoute = 'dashboard/workplace';

export const routes = [
  {
    name: '仪表盘',
    key: 'dashboard',
    children: [
      {
        name: '工作台',
        key: 'dashboard/workplace',
      },
    ],
  },
  {
    name: '开票管理',
    key: 'invoice',
    children: [
      {
        name: '待开发票',
        key: 'invoice/apply',
      },
      {
        name: '开票历史',
        key: 'invoice/history',
      },
    ],
  },
  {
    name: '订单管理',
    key: 'order',
    children: [
      {
        name: '开通服务',
        key: 'order/open-service',
      },
    ],
  },
  {
    name: '客户管理',
    key: 'customer',
    children: [
      {
        name: '用户列表',
        key: 'customer/users',
      },
      {
        name: '企业列表',
        key: 'customer/enterprise',
      },
    ],
  },
  {
    name: '营销工具',
    key: 'marketing',
    children: [
      {
        name: '优惠券',
        key: 'marketing/coupon',
      },
      {
        name: '测试1',
        key: 'visualization/data-analysis',
      },
      {
        name: '测试2',
        key: 'visualization/multi-dimension-data-analysis',
      },
      {
        name: '403',
        key: 'exception/403',
      },
      {
        name: '404',
        key: 'exception/404',
      },
      {
        name: '500',
        key: 'exception/500',
      },
    ],
  },
  {
    name: '产品维护',
    key: 'system',
    children: [
      {
        name: '预设币种',
        key: 'system/currency',
      },
    ],
  },
];

export const getName = (path: string, routes) => {
  return routes.find((item) => {
    const itemPath = `/${item.key}`;
    if (path === itemPath) {
      return item.name;
    } else if (item.children) {
      return getName(path, item.children);
    }
  });
};
