/*
 * @Description: 填写描述
 * @Author: 填写作者
 * @Date: 2022-02-04 00:12:00
 */

// 管理后台主要api服务器

const dev = 'http://localhost:3000/api';
const test = '';
const pro = '/api';

export let host = null;

switch (process.env.NODE_ENV) {
  case 'development':
    host = dev;
    break;
  case 'production':
    host = pro;
    break;
  case 'test':
    host = test;
    break;
}
