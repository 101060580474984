/*
 * @Description: 填写描述
 * @Author: 填写作者
 * @Date: 2022-01-13 02:43:15
 */
import { Carousel } from '@arco-design/web-react';
import React from 'react';

import styles from './style/index.module.less';

export default function LoginBannber() {
  const data = [
    {
      slogan: '业务+财务一体化管理系统',
      subSlogan: '整合CRM、进销存、云财务于一体，打破数据孤岛',
      image:
        'http://p1-arco.byteimg.com/tos-cn-i-uwbnlip3yd/6c85f43aed61e320ebec194e6a78d6d3.png~tplv-uwbnlip3yd-png.png',
    },
  ];
  return (
    <Carousel className={styles.carousel} animation="fade">
      {data.map((item, index) => (
        <div key={`${index}`}>
          <div className={styles['carousel-item']}>
            <div className={styles['carousel-title']}>{item.slogan}</div>
            <div className={styles['carousel-sub-title']}>{item.subSlogan}</div>
            <img className={styles['carousel-image']} src={item.image} />
          </div>
        </div>
      ))}
    </Carousel>
  );
}
