/*
 * @Description: 二次封装axios
 * @Author: 填写作者
 * @Date: 2022-03-14 01:28:18
 */

import { Message } from '@arco-design/web-react';
import axios from 'axios';
import Cookies from 'js-cookie';

axios.defaults.timeout = 10000;

/**
 * 请求拦截器
 */
axios.interceptors.request.use(
  (config) => {
    // config.data = JSON.stringify(config.data);
    config.headers = {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access_token'),
    };
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

/**
 * 响应拦截器
 */
axios.interceptors.response.use(
  (response) => {
    if (response.data.code === 20001) {
      localStorage.removeItem('access_token');
      window.location.href = '/login';
    }
    return response;
  },
  (error) => {
    httpError(error);
  }
);

/**
 * 封装get方法
 * @param url  请求url
 * @param params  请求参数
 * @returns {Promise}
 */

export function get(url, params = {}) {
  return axios({ url: url, method: 'get', params: params }).then((response) => {
    return response.data;
  });
}

/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function post(url, data) {
  return axios.post(url, data).then((response) => {
    return response.data;
  });
}

/**
 * 封装patch请求
 * @param url
 * @param data
 * @returns {Promise}
 */
export function patch(url, data = {}) {
  return axios.patch(url, data).then((response) => {
    return response.data;
  });
}

/**
 * 封装put请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function put(url, data) {
  return axios.put(url, data).then((response) => {
    return response.data;
  });
}

//统一接口处理，返回数据
export default async function request(method, url, param?): Promise<any> {
  switch (method) {
    case 'get':
      return await get(url, param);
    case 'post':
      return await post(url, param);
    case 'patch':
      return await patch(url, param);
    case 'put':
      return await put(url, param);
  }
}

//失败提示
function httpError(err) {
  if (err && err.response) {
    switch (err.response.status) {
      case 400:
        Message.error(err.response.data.error.details);
        break;
      case 401:
        Message.error('未授权，请登录');
        localStorage.removeItem('access_token');
        Cookies.remove('access_token');
        window.location.href = '/login';
        break;

      case 403:
        Message.error('拒绝访问');
        break;

      case 404:
        Message.error('请求地址错误');
        break;

      case 408:
        Message.error('请求超时');
        break;

      case 500:
        Message.error('服务器内部错误');
        break;

      case 501:
        Message.error('服务未实现');
        break;

      case 502:
        Message.error('网关错误');
        break;

      case 503:
        Message.error('服务不可用');
        break;

      case 504:
        Message.error('网关超时');
        break;

      case 505:
        Message.error('HTTP版本不受支持');
        break;
      default:
    }
  }
}
