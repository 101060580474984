/*
 * @Description: 填写描述
 * @Author: 填写作者
 * @Date: 2022-01-13 02:43:15
 */
import {
  Form,
  Input,
  Checkbox,
  Link,
  Button,
  Space,
  Message,
} from '@arco-design/web-react';
import { FormInstance } from '@arco-design/web-react/es/Form';
import { IconLock, IconUser } from '@arco-design/web-react/icon';
import React, { useRef, useState } from 'react';
import styles from './style/index.module.less';
import { defaultRoute } from '@/routes';
import { host } from '@/api-host';
import request from '@/utils/request';

import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

export default function LoginForm() {
  const formRef = useRef<FormInstance>();
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();

  async function login(params) {
    setErrorMessage('');
    setLoading(true);

    const fetchUserInfo = () => {
      request('get', `${host}/user/userinfo`).then((res: any) => {
        dispatch({
          type: 'update-userInfo',
          payload: { userInfo: res.data },
        });
      });
    };

    await request('post', `${host}/user/login`, params)
      .then((res: any) => {
        const { code, data, msg } = res;
        if (code === 0) {
          history.push(defaultRoute);
          Message.success({ content: '登录成功', duration: 500 });

          // 记录jwttoken
          localStorage.setItem('access_token', data.access_token);
          // 请求当前用户的资料并存入redux
          fetchUserInfo();
        } else {
          setErrorMessage(msg || '登录出错，请刷新重试');
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function onSubmitClick() {
    formRef.current.validate().then((values) => {
      login(values);
    });
  }

  return (
    <div className={styles['login-form-wrapper']}>
      <div className={styles['login-form-title']}>贝云运营管理平台</div>
      {/* <div className={styles['login-form-sub-title']}>
        打造数据闭环，助力高效增长
      </div> */}
      <div className={styles['login-form-error-msg']}>{errorMessage}</div>
      <Form className={styles['login-form']} layout="vertical" ref={formRef}>
        <Form.Item
          field="mobile_phone"
          rules={[{ required: true, message: '用户名不能为空' }]}
        >
          <Input
            prefix={<IconUser />}
            placeholder="输入手机号码"
            size="large"
            onPressEnter={onSubmitClick}
          />
        </Form.Item>
        <Form.Item
          field="password"
          rules={[{ required: true, message: '密码不能为空' }]}
        >
          <Input.Password
            prefix={<IconLock />}
            placeholder="输入登录密码"
            size="large"
            onPressEnter={onSubmitClick}
          />
        </Form.Item>
        <Space size={16} direction="vertical">
          <Button
            type="primary"
            long
            onClick={onSubmitClick}
            size="large"
            loading={loading}
          >
            登录
          </Button>
          {/* <div className={styles['login-form-password-actions']}>
            <Checkbox>记住密码</Checkbox>
            <Link>忘记密码？</Link>
          </div> */}
        </Space>
      </Form>
    </div>
  );
}
