/*
 * @Description: 填写描述
 * @Author: 填写作者
 * @Date: 2022-01-10 23:36:19
 */
import defaultSettings from '../settings.json';
import { isSSR } from '@/utils/is';
import storage from '@/utils/storage';

const defaultTheme = storage.getItem('arco-theme') || 'light';
function changeTheme(newTheme?: 'string') {
  if ((newTheme || defaultTheme) === 'dark') {
    document.body.setAttribute('arco-theme', 'dark');
  } else {
    document.body.removeAttribute('arco-theme');
  }
}

// init page theme
if (!isSSR) {
  changeTheme();
}

export interface GlobalState {
  theme?: string;
  settings?: typeof defaultSettings;
  userInfo?: {
    user_id?: number;
    real_name?: string;
    photo_path?: string;
    type?: number;
    mobile_phone?: string;
  };
}

const initialState: GlobalState = {
  theme: defaultTheme,
  settings: defaultSettings,
};

export default function store(state = initialState, action) {
  switch (action.type) {
    case 'toggle-theme': {
      const { theme } = action.payload;
      if (theme === 'light' || theme === 'dark') {
        localStorage.setItem('arco-theme', theme);
        changeTheme(theme);
      }

      return {
        ...state,
        theme,
      };
    }
    case 'update-settings': {
      const { settings } = action.payload;
      return {
        ...state,
        settings,
      };
    }
    case 'update-userInfo': {
      const { userInfo = {} } = action.payload;
      return {
        ...state,
        // userLoading,
        userInfo,
      };
    }
    default:
      return state;
  }
}
